@font-face {
  font-family: 'Montserrat-Bold';
  font-weight: bold;
  src: url('./assets/fonts/Montserrat-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Montserrat-BoldItalic';
  font-weight: bold;
  font-style: italic;
  src:  url('./assets/fonts/Montserrat-BoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  font-weight: 600; /* SemiBold font-weight */
  src:  url('./assets/fonts/Montserrat-SemiBold.ttf') format('ttf');
}
